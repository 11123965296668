import React from 'react';
import './LogoBanner.scss';

function LogoBanner() {
  return (
    <div className="logo-banner">
      <svg className="logo-jared" viewBox="0 0 793 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Jared</title>
        <mask id="path-1-outside-1" fill="white">
          {/* JARED */}
          <path d="M34.6406 3.75H48.7031V51C48.7031 55.3438 47.7344 59.1875 45.7969 62.5312C43.8906 65.8438 41.1875 68.4062 37.6875 70.2188C34.1875 72.0312 30.2344 72.9375 25.8281 72.9375C18.6094 72.9375 12.9844 71.1094 8.95312 67.4531C4.92188 63.7656 2.90625 58.5625 2.90625 51.8438H17.0625C17.0625 55.1875 17.7656 57.6562 19.1719 59.25C20.5781 60.8438 22.7969 61.6406 25.8281 61.6406C28.5156 61.6406 30.6562 60.7188 32.25 58.875C33.8438 57.0312 34.6406 54.4062 34.6406 51V3.75Z"/>
          <path d="M98.25 57.9375H73.5938L68.9062 72H53.9531L79.3594 3.75H92.3906L117.938 72H102.984L98.25 57.9375ZM77.3906 46.5469H94.4531L85.875 21L77.3906 46.5469Z"/>
          <path d="M149.578 47.0156H138.375V72H124.312V3.75H149.672C157.734 3.75 163.953 5.54688 168.328 9.14062C172.703 12.7344 174.891 17.8125 174.891 24.375C174.891 29.0312 173.875 32.9219 171.844 36.0469C169.844 39.1406 166.797 41.6094 162.703 43.4531L177.469 71.3438V72H162.375L149.578 47.0156ZM138.375 35.625H149.719C153.25 35.625 155.984 34.7344 157.922 32.9531C159.859 31.1406 160.828 28.6562 160.828 25.5C160.828 22.2812 159.906 19.75 158.062 17.9062C156.25 16.0625 153.453 15.1406 149.672 15.1406H138.375V35.625Z"/>
          <path d="M226.688 42.4219H199.688V60.7031H231.375V72H185.625V3.75H231.281V15.1406H199.688V31.4062H226.688V42.4219Z"/>
          <path d="M239.625 72V3.75H260.625C266.625 3.75 271.984 5.10938 276.703 7.82812C281.453 10.5156 285.156 14.3594 287.812 19.3594C290.469 24.3281 291.797 29.9844 291.797 36.3281V39.4688C291.797 45.8125 290.484 51.4531 287.859 56.3906C285.266 61.3281 281.594 65.1562 276.844 67.875C272.094 70.5938 266.734 71.9688 260.766 72H239.625ZM253.688 15.1406V60.7031H260.484C265.984 60.7031 270.188 58.9062 273.094 55.3125C276 51.7188 277.484 46.5781 277.547 39.8906V36.2812C277.547 29.3438 276.109 24.0938 273.234 20.5312C270.359 16.9375 266.156 15.1406 260.625 15.1406H253.688Z"/>
        </mask>
        {/* JARED */}
        <path d="M34.6406 3.75H48.7031V51C48.7031 55.3438 47.7344 59.1875 45.7969 62.5312C43.8906 65.8438 41.1875 68.4062 37.6875 70.2188C34.1875 72.0312 30.2344 72.9375 25.8281 72.9375C18.6094 72.9375 12.9844 71.1094 8.95312 67.4531C4.92188 63.7656 2.90625 58.5625 2.90625 51.8438H17.0625C17.0625 55.1875 17.7656 57.6562 19.1719 59.25C20.5781 60.8438 22.7969 61.6406 25.8281 61.6406C28.5156 61.6406 30.6562 60.7188 32.25 58.875C33.8438 57.0312 34.6406 54.4062 34.6406 51V3.75Z" stroke="white" stroke-width="4" mask="url(#path-1-outside-1)"/>
        <path d="M98.25 57.9375H73.5938L68.9062 72H53.9531L79.3594 3.75H92.3906L117.938 72H102.984L98.25 57.9375ZM77.3906 46.5469H94.4531L85.875 21L77.3906 46.5469Z" stroke="white" stroke-width="4" mask="url(#path-1-outside-1)"/>
        <path d="M149.578 47.0156H138.375V72H124.312V3.75H149.672C157.734 3.75 163.953 5.54688 168.328 9.14062C172.703 12.7344 174.891 17.8125 174.891 24.375C174.891 29.0312 173.875 32.9219 171.844 36.0469C169.844 39.1406 166.797 41.6094 162.703 43.4531L177.469 71.3438V72H162.375L149.578 47.0156ZM138.375 35.625H149.719C153.25 35.625 155.984 34.7344 157.922 32.9531C159.859 31.1406 160.828 28.6562 160.828 25.5C160.828 22.2812 159.906 19.75 158.062 17.9062C156.25 16.0625 153.453 15.1406 149.672 15.1406H138.375V35.625Z" stroke="white" stroke-width="4" mask="url(#path-1-outside-1)"/>
        <path d="M226.688 42.4219H199.688V60.7031H231.375V72H185.625V3.75H231.281V15.1406H199.688V31.4062H226.688V42.4219Z" stroke="white" stroke-width="4" mask="url(#path-1-outside-1)"/>
        <path d="M239.625 72V3.75H260.625C266.625 3.75 271.984 5.10938 276.703 7.82812C281.453 10.5156 285.156 14.3594 287.812 19.3594C290.469 24.3281 291.797 29.9844 291.797 36.3281V39.4688C291.797 45.8125 290.484 51.4531 287.859 56.3906C285.266 61.3281 281.594 65.1562 276.844 67.875C272.094 70.5938 266.734 71.9688 260.766 72H239.625ZM253.688 15.1406V60.7031H260.484C265.984 60.7031 270.188 58.9062 273.094 55.3125C276 51.7188 277.484 46.5781 277.547 39.8906V36.2812C277.547 29.3438 276.109 24.0938 273.234 20.5312C270.359 16.9375 266.156 15.1406 260.625 15.1406H253.688Z" stroke="white" stroke-width="4" mask="url(#path-1-outside-1)"/>
      </svg>      
      <svg className="logo-harbison" viewBox="0 0 793 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Harbison</title>
        <mask id="path-2-outside-2" fill="white">
          {/* HARBISON */}
          <path d="M381.516 72H367.453V42.75H340.031V72H325.969V3.75H340.031V31.4062H367.453V3.75H381.516V72Z"/>
          <path d="M433.219 57.9375H408.562L403.875 72H388.922L414.328 3.75H427.359L452.906 72H437.953L433.219 57.9375ZM412.359 46.5469H429.422L420.844 21L412.359 46.5469Z"/>
          <path d="M484.547 47.0156H473.344V72H459.281V3.75H484.641C492.703 3.75 498.922 5.54688 503.297 9.14062C507.672 12.7344 509.859 17.8125 509.859 24.375C509.859 29.0312 508.844 32.9219 506.812 36.0469C504.812 39.1406 501.766 41.6094 497.672 43.4531L512.438 71.3438V72H497.344L484.547 47.0156ZM473.344 35.625H484.688C488.219 35.625 490.953 34.7344 492.891 32.9531C494.828 31.1406 495.797 28.6562 495.797 25.5C495.797 22.2812 494.875 19.75 493.031 17.9062C491.219 16.0625 488.422 15.1406 484.641 15.1406H473.344V35.625Z"/>
          <path d="M520.594 72V3.75H544.5C552.781 3.75 559.062 5.34375 563.344 8.53125C567.625 11.6875 569.766 16.3281 569.766 22.4531C569.766 25.7969 568.906 28.75 567.188 31.3125C565.469 33.8438 563.078 35.7031 560.016 36.8906C563.516 37.7656 566.266 39.5312 568.266 42.1875C570.297 44.8438 571.312 48.0938 571.312 51.9375C571.312 58.5 569.219 63.4688 565.031 66.8438C560.844 70.2188 554.875 71.9375 547.125 72H520.594ZM534.656 42.2812V60.7031H546.703C550.016 60.7031 552.594 59.9219 554.438 58.3594C556.312 56.7656 557.25 54.5781 557.25 51.7969C557.25 45.5469 554.016 42.375 547.547 42.2812H534.656ZM534.656 32.3438H545.062C552.156 32.2188 555.703 29.3906 555.703 23.8594C555.703 20.7656 554.797 18.5469 552.984 17.2031C551.203 15.8281 548.375 15.1406 544.5 15.1406H534.656V32.3438Z"/>
          <path d="M596.859 72H582.797V3.75H596.859V72Z"/>
          <path d="M645.328 54.0938C645.328 51.4375 644.391 49.4062 642.516 48C640.641 46.5625 637.266 45.0625 632.391 43.5C627.516 41.9062 623.656 40.3438 620.812 38.8125C613.062 34.625 609.188 28.9844 609.188 21.8906C609.188 18.2031 610.219 14.9219 612.281 12.0469C614.375 9.14062 617.359 6.875 621.234 5.25C625.141 3.625 629.516 2.8125 634.359 2.8125C639.234 2.8125 643.578 3.70312 647.391 5.48438C651.203 7.23438 654.156 9.71875 656.25 12.9375C658.375 16.1562 659.438 19.8125 659.438 23.9062H645.375C645.375 20.7812 644.391 18.3594 642.422 16.6406C640.453 14.8906 637.688 14.0156 634.125 14.0156C630.688 14.0156 628.016 14.75 626.109 16.2188C624.203 17.6562 623.25 19.5625 623.25 21.9375C623.25 24.1562 624.359 26.0156 626.578 27.5156C628.828 29.0156 632.125 30.4219 636.469 31.7344C644.469 34.1406 650.297 37.125 653.953 40.6875C657.609 44.25 659.438 48.6875 659.438 54C659.438 59.9062 657.203 64.5469 652.734 67.9219C648.266 71.2656 642.25 72.9375 634.688 72.9375C629.438 72.9375 624.656 71.9844 620.344 70.0781C616.031 68.1406 612.734 65.5 610.453 62.1562C608.203 58.8125 607.078 54.9375 607.078 50.5312H621.188C621.188 58.0625 625.688 61.8281 634.688 61.8281C638.031 61.8281 640.641 61.1562 642.516 59.8125C644.391 58.4375 645.328 56.5312 645.328 54.0938Z"/>
          <path d="M725.062 39.4219C725.062 46.1406 723.875 52.0312 721.5 57.0938C719.125 62.1562 715.719 66.0625 711.281 68.8125C706.875 71.5625 701.812 72.9375 696.094 72.9375C690.438 72.9375 685.391 71.5781 680.953 68.8594C676.516 66.1406 673.078 62.2656 670.641 57.2344C668.203 52.1719 666.969 46.3594 666.938 39.7969V36.4219C666.938 29.7031 668.141 23.7969 670.547 18.7031C672.984 13.5781 676.406 9.65625 680.812 6.9375C685.25 4.1875 690.312 2.8125 696 2.8125C701.688 2.8125 706.734 4.1875 711.141 6.9375C715.578 9.65625 719 13.5781 721.406 18.7031C723.844 23.7969 725.062 29.6875 725.062 36.375V39.4219ZM710.812 36.3281C710.812 29.1719 709.531 23.7344 706.969 20.0156C704.406 16.2969 700.75 14.4375 696 14.4375C691.281 14.4375 687.641 16.2812 685.078 19.9688C682.516 23.625 681.219 29 681.188 36.0938V39.4219C681.188 46.3906 682.469 51.7969 685.031 55.6406C687.594 59.4844 691.281 61.4062 696.094 61.4062C700.812 61.4062 704.438 59.5625 706.969 55.875C709.5 52.1562 710.781 46.75 710.812 39.6562V36.3281Z"/>
          <path d="M790.781 72H776.719L749.344 27.0938V72H735.281V3.75H749.344L776.766 48.75V3.75H790.781V72Z"/>
        </mask>  
        {/* HARBISON */}
        <path d="M381.516 72H367.453V42.75H340.031V72H325.969V3.75H340.031V31.4062H367.453V3.75H381.516V72Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M433.219 57.9375H408.562L403.875 72H388.922L414.328 3.75H427.359L452.906 72H437.953L433.219 57.9375ZM412.359 46.5469H429.422L420.844 21L412.359 46.5469Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M484.547 47.0156H473.344V72H459.281V3.75H484.641C492.703 3.75 498.922 5.54688 503.297 9.14062C507.672 12.7344 509.859 17.8125 509.859 24.375C509.859 29.0312 508.844 32.9219 506.812 36.0469C504.812 39.1406 501.766 41.6094 497.672 43.4531L512.438 71.3438V72H497.344L484.547 47.0156ZM473.344 35.625H484.688C488.219 35.625 490.953 34.7344 492.891 32.9531C494.828 31.1406 495.797 28.6562 495.797 25.5C495.797 22.2812 494.875 19.75 493.031 17.9062C491.219 16.0625 488.422 15.1406 484.641 15.1406H473.344V35.625Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M520.594 72V3.75H544.5C552.781 3.75 559.062 5.34375 563.344 8.53125C567.625 11.6875 569.766 16.3281 569.766 22.4531C569.766 25.7969 568.906 28.75 567.188 31.3125C565.469 33.8438 563.078 35.7031 560.016 36.8906C563.516 37.7656 566.266 39.5312 568.266 42.1875C570.297 44.8438 571.312 48.0938 571.312 51.9375C571.312 58.5 569.219 63.4688 565.031 66.8438C560.844 70.2188 554.875 71.9375 547.125 72H520.594ZM534.656 42.2812V60.7031H546.703C550.016 60.7031 552.594 59.9219 554.438 58.3594C556.312 56.7656 557.25 54.5781 557.25 51.7969C557.25 45.5469 554.016 42.375 547.547 42.2812H534.656ZM534.656 32.3438H545.062C552.156 32.2188 555.703 29.3906 555.703 23.8594C555.703 20.7656 554.797 18.5469 552.984 17.2031C551.203 15.8281 548.375 15.1406 544.5 15.1406H534.656V32.3438Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M596.859 72H582.797V3.75H596.859V72Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M645.328 54.0938C645.328 51.4375 644.391 49.4062 642.516 48C640.641 46.5625 637.266 45.0625 632.391 43.5C627.516 41.9062 623.656 40.3438 620.812 38.8125C613.062 34.625 609.188 28.9844 609.188 21.8906C609.188 18.2031 610.219 14.9219 612.281 12.0469C614.375 9.14062 617.359 6.875 621.234 5.25C625.141 3.625 629.516 2.8125 634.359 2.8125C639.234 2.8125 643.578 3.70312 647.391 5.48438C651.203 7.23438 654.156 9.71875 656.25 12.9375C658.375 16.1562 659.438 19.8125 659.438 23.9062H645.375C645.375 20.7812 644.391 18.3594 642.422 16.6406C640.453 14.8906 637.688 14.0156 634.125 14.0156C630.688 14.0156 628.016 14.75 626.109 16.2188C624.203 17.6562 623.25 19.5625 623.25 21.9375C623.25 24.1562 624.359 26.0156 626.578 27.5156C628.828 29.0156 632.125 30.4219 636.469 31.7344C644.469 34.1406 650.297 37.125 653.953 40.6875C657.609 44.25 659.438 48.6875 659.438 54C659.438 59.9062 657.203 64.5469 652.734 67.9219C648.266 71.2656 642.25 72.9375 634.688 72.9375C629.438 72.9375 624.656 71.9844 620.344 70.0781C616.031 68.1406 612.734 65.5 610.453 62.1562C608.203 58.8125 607.078 54.9375 607.078 50.5312H621.188C621.188 58.0625 625.688 61.8281 634.688 61.8281C638.031 61.8281 640.641 61.1562 642.516 59.8125C644.391 58.4375 645.328 56.5312 645.328 54.0938Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M725.062 39.4219C725.062 46.1406 723.875 52.0312 721.5 57.0938C719.125 62.1562 715.719 66.0625 711.281 68.8125C706.875 71.5625 701.812 72.9375 696.094 72.9375C690.438 72.9375 685.391 71.5781 680.953 68.8594C676.516 66.1406 673.078 62.2656 670.641 57.2344C668.203 52.1719 666.969 46.3594 666.938 39.7969V36.4219C666.938 29.7031 668.141 23.7969 670.547 18.7031C672.984 13.5781 676.406 9.65625 680.812 6.9375C685.25 4.1875 690.312 2.8125 696 2.8125C701.688 2.8125 706.734 4.1875 711.141 6.9375C715.578 9.65625 719 13.5781 721.406 18.7031C723.844 23.7969 725.062 29.6875 725.062 36.375V39.4219ZM710.812 36.3281C710.812 29.1719 709.531 23.7344 706.969 20.0156C704.406 16.2969 700.75 14.4375 696 14.4375C691.281 14.4375 687.641 16.2812 685.078 19.9688C682.516 23.625 681.219 29 681.188 36.0938V39.4219C681.188 46.3906 682.469 51.7969 685.031 55.6406C687.594 59.4844 691.281 61.4062 696.094 61.4062C700.812 61.4062 704.438 59.5625 706.969 55.875C709.5 52.1562 710.781 46.75 710.812 39.6562V36.3281Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
        <path d="M790.781 72H776.719L749.344 27.0938V72H735.281V3.75H749.344L776.766 48.75V3.75H790.781V72Z" stroke="white" stroke-width="4" mask="url(#path-2-outside-2)"/>
      </svg>  
      <svg className="logo-software" viewBox="0 0 793 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Software</title>
        <mask id="path-3-outside-3" fill="white">  
          {/* software */}
          <path d="M160.312 137.656C160.312 136.552 159.76 135.688 158.656 135.062C157.573 134.417 155.823 133.844 153.406 133.344C145.365 131.656 141.344 128.24 141.344 123.094C141.344 120.094 142.583 117.594 145.062 115.594C147.562 113.573 150.823 112.562 154.844 112.562C159.135 112.562 162.562 113.573 165.125 115.594C167.708 117.615 169 120.24 169 123.469H159.969C159.969 122.177 159.552 121.115 158.719 120.281C157.885 119.427 156.583 119 154.812 119C153.292 119 152.115 119.344 151.281 120.031C150.448 120.719 150.031 121.594 150.031 122.656C150.031 123.656 150.5 124.469 151.438 125.094C152.396 125.698 154 126.229 156.25 126.688C158.5 127.125 160.396 127.625 161.938 128.188C166.708 129.938 169.094 132.969 169.094 137.281C169.094 140.365 167.771 142.865 165.125 144.781C162.479 146.677 159.062 147.625 154.875 147.625C152.042 147.625 149.521 147.125 147.312 146.125C145.125 145.104 143.406 143.719 142.156 141.969C140.906 140.198 140.281 138.292 140.281 136.25H148.844C148.927 137.854 149.521 139.083 150.625 139.938C151.729 140.792 153.208 141.219 155.062 141.219C156.792 141.219 158.094 140.896 158.969 140.25C159.865 139.583 160.312 138.719 160.312 137.656Z"/>
          <path d="M173.531 129.781C173.531 126.427 174.177 123.438 175.469 120.812C176.76 118.188 178.615 116.156 181.031 114.719C183.469 113.281 186.292 112.562 189.5 112.562C194.062 112.562 197.781 113.958 200.656 116.75C203.552 119.542 205.167 123.333 205.5 128.125L205.562 130.438C205.562 135.625 204.115 139.792 201.219 142.938C198.323 146.062 194.438 147.625 189.562 147.625C184.688 147.625 180.792 146.062 177.875 142.938C174.979 139.812 173.531 135.562 173.531 130.188V129.781ZM182.562 130.438C182.562 133.646 183.167 136.104 184.375 137.812C185.583 139.5 187.312 140.344 189.562 140.344C191.75 140.344 193.458 139.51 194.688 137.844C195.917 136.156 196.531 133.469 196.531 129.781C196.531 126.635 195.917 124.198 194.688 122.469C193.458 120.74 191.729 119.875 189.5 119.875C187.292 119.875 185.583 120.74 184.375 122.469C183.167 124.177 182.562 126.833 182.562 130.438Z"/>
          <path d="M213.594 147V119.812H208.562V113.188H213.594V110.312C213.594 106.521 214.677 103.583 216.844 101.5C219.031 99.3958 222.083 98.3438 226 98.3438C227.25 98.3438 228.781 98.5521 230.594 98.9688L230.5 105.969C229.75 105.781 228.833 105.688 227.75 105.688C224.354 105.688 222.656 107.281 222.656 110.469V113.188H229.375V119.812H222.656V147H213.594Z"/>
          <path d="M244.875 104.875V113.188H250.656V119.812H244.875V136.688C244.875 137.938 245.115 138.833 245.594 139.375C246.073 139.917 246.99 140.188 248.344 140.188C249.344 140.188 250.229 140.115 251 139.969V146.812C249.229 147.354 247.406 147.625 245.531 147.625C239.198 147.625 235.969 144.427 235.844 138.031V119.812H230.906V113.188H235.844V104.875H244.875Z"/>
          <path d="M285.094 134.719L289.531 113.188H298.25L289.625 147H282.062L275.656 125.719L269.25 147H261.719L253.094 113.188H261.812L266.219 134.688L272.406 113.188H278.938L285.094 134.719Z"/>
          <path d="M322.344 147C321.927 146.188 321.625 145.177 321.438 143.969C319.25 146.406 316.406 147.625 312.906 147.625C309.594 147.625 306.844 146.667 304.656 144.75C302.49 142.833 301.406 140.417 301.406 137.5C301.406 133.917 302.729 131.167 305.375 129.25C308.042 127.333 311.885 126.365 316.906 126.344H321.062V124.406C321.062 122.844 320.656 121.594 319.844 120.656C319.052 119.719 317.792 119.25 316.062 119.25C314.542 119.25 313.344 119.615 312.469 120.344C311.615 121.073 311.188 122.073 311.188 123.344H302.156C302.156 121.385 302.76 119.573 303.969 117.906C305.177 116.24 306.885 114.938 309.094 114C311.302 113.042 313.781 112.562 316.531 112.562C320.698 112.562 324 113.615 326.438 115.719C328.896 117.802 330.125 120.74 330.125 124.531V139.188C330.146 142.396 330.594 144.823 331.469 146.469V147H322.344ZM314.875 140.719C316.208 140.719 317.438 140.427 318.562 139.844C319.688 139.24 320.521 138.438 321.062 137.438V131.625H317.688C313.167 131.625 310.76 133.188 310.469 136.312L310.438 136.844C310.438 137.969 310.833 138.896 311.625 139.625C312.417 140.354 313.5 140.719 314.875 140.719Z"/>
          <path d="M356.062 121.656C354.833 121.49 353.75 121.406 352.812 121.406C349.396 121.406 347.156 122.562 346.094 124.875V147H337.062V113.188H345.594L345.844 117.219C347.656 114.115 350.167 112.562 353.375 112.562C354.375 112.562 355.312 112.698 356.188 112.969L356.062 121.656Z"/>
          <path d="M375.375 147.625C370.417 147.625 366.375 146.104 363.25 143.062C360.146 140.021 358.594 135.969 358.594 130.906V130.031C358.594 126.635 359.25 123.604 360.562 120.938C361.875 118.25 363.729 116.188 366.125 114.75C368.542 113.292 371.292 112.562 374.375 112.562C379 112.562 382.635 114.021 385.281 116.938C387.948 119.854 389.281 123.99 389.281 129.344V133.031H367.75C368.042 135.24 368.917 137.01 370.375 138.344C371.854 139.677 373.719 140.344 375.969 140.344C379.448 140.344 382.167 139.083 384.125 136.562L388.562 141.531C387.208 143.448 385.375 144.948 383.062 146.031C380.75 147.094 378.188 147.625 375.375 147.625ZM374.344 119.875C372.552 119.875 371.094 120.479 369.969 121.688C368.865 122.896 368.156 124.625 367.844 126.875H380.406V126.156C380.365 124.156 379.823 122.615 378.781 121.531C377.74 120.427 376.26 119.875 374.344 119.875Z"/>
        
        </mask>  
        {/* software */}
        <path d="M160.312 137.656C160.312 136.552 159.76 135.688 158.656 135.062C157.573 134.417 155.823 133.844 153.406 133.344C145.365 131.656 141.344 128.24 141.344 123.094C141.344 120.094 142.583 117.594 145.062 115.594C147.562 113.573 150.823 112.562 154.844 112.562C159.135 112.562 162.562 113.573 165.125 115.594C167.708 117.615 169 120.24 169 123.469H159.969C159.969 122.177 159.552 121.115 158.719 120.281C157.885 119.427 156.583 119 154.812 119C153.292 119 152.115 119.344 151.281 120.031C150.448 120.719 150.031 121.594 150.031 122.656C150.031 123.656 150.5 124.469 151.438 125.094C152.396 125.698 154 126.229 156.25 126.688C158.5 127.125 160.396 127.625 161.938 128.188C166.708 129.938 169.094 132.969 169.094 137.281C169.094 140.365 167.771 142.865 165.125 144.781C162.479 146.677 159.062 147.625 154.875 147.625C152.042 147.625 149.521 147.125 147.312 146.125C145.125 145.104 143.406 143.719 142.156 141.969C140.906 140.198 140.281 138.292 140.281 136.25H148.844C148.927 137.854 149.521 139.083 150.625 139.938C151.729 140.792 153.208 141.219 155.062 141.219C156.792 141.219 158.094 140.896 158.969 140.25C159.865 139.583 160.312 138.719 160.312 137.656Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M173.531 129.781C173.531 126.427 174.177 123.438 175.469 120.812C176.76 118.188 178.615 116.156 181.031 114.719C183.469 113.281 186.292 112.562 189.5 112.562C194.062 112.562 197.781 113.958 200.656 116.75C203.552 119.542 205.167 123.333 205.5 128.125L205.562 130.438C205.562 135.625 204.115 139.792 201.219 142.938C198.323 146.062 194.438 147.625 189.562 147.625C184.688 147.625 180.792 146.062 177.875 142.938C174.979 139.812 173.531 135.562 173.531 130.188V129.781ZM182.562 130.438C182.562 133.646 183.167 136.104 184.375 137.812C185.583 139.5 187.312 140.344 189.562 140.344C191.75 140.344 193.458 139.51 194.688 137.844C195.917 136.156 196.531 133.469 196.531 129.781C196.531 126.635 195.917 124.198 194.688 122.469C193.458 120.74 191.729 119.875 189.5 119.875C187.292 119.875 185.583 120.74 184.375 122.469C183.167 124.177 182.562 126.833 182.562 130.438Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M213.594 147V119.812H208.562V113.188H213.594V110.312C213.594 106.521 214.677 103.583 216.844 101.5C219.031 99.3958 222.083 98.3438 226 98.3438C227.25 98.3438 228.781 98.5521 230.594 98.9688L230.5 105.969C229.75 105.781 228.833 105.688 227.75 105.688C224.354 105.688 222.656 107.281 222.656 110.469V113.188H229.375V119.812H222.656V147H213.594Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M244.875 104.875V113.188H250.656V119.812H244.875V136.688C244.875 137.938 245.115 138.833 245.594 139.375C246.073 139.917 246.99 140.188 248.344 140.188C249.344 140.188 250.229 140.115 251 139.969V146.812C249.229 147.354 247.406 147.625 245.531 147.625C239.198 147.625 235.969 144.427 235.844 138.031V119.812H230.906V113.188H235.844V104.875H244.875Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M285.094 134.719L289.531 113.188H298.25L289.625 147H282.062L275.656 125.719L269.25 147H261.719L253.094 113.188H261.812L266.219 134.688L272.406 113.188H278.938L285.094 134.719Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M322.344 147C321.927 146.188 321.625 145.177 321.438 143.969C319.25 146.406 316.406 147.625 312.906 147.625C309.594 147.625 306.844 146.667 304.656 144.75C302.49 142.833 301.406 140.417 301.406 137.5C301.406 133.917 302.729 131.167 305.375 129.25C308.042 127.333 311.885 126.365 316.906 126.344H321.062V124.406C321.062 122.844 320.656 121.594 319.844 120.656C319.052 119.719 317.792 119.25 316.062 119.25C314.542 119.25 313.344 119.615 312.469 120.344C311.615 121.073 311.188 122.073 311.188 123.344H302.156C302.156 121.385 302.76 119.573 303.969 117.906C305.177 116.24 306.885 114.938 309.094 114C311.302 113.042 313.781 112.562 316.531 112.562C320.698 112.562 324 113.615 326.438 115.719C328.896 117.802 330.125 120.74 330.125 124.531V139.188C330.146 142.396 330.594 144.823 331.469 146.469V147H322.344ZM314.875 140.719C316.208 140.719 317.438 140.427 318.562 139.844C319.688 139.24 320.521 138.438 321.062 137.438V131.625H317.688C313.167 131.625 310.76 133.188 310.469 136.312L310.438 136.844C310.438 137.969 310.833 138.896 311.625 139.625C312.417 140.354 313.5 140.719 314.875 140.719Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M356.062 121.656C354.833 121.49 353.75 121.406 352.812 121.406C349.396 121.406 347.156 122.562 346.094 124.875V147H337.062V113.188H345.594L345.844 117.219C347.656 114.115 350.167 112.562 353.375 112.562C354.375 112.562 355.312 112.698 356.188 112.969L356.062 121.656Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
        <path d="M375.375 147.625C370.417 147.625 366.375 146.104 363.25 143.062C360.146 140.021 358.594 135.969 358.594 130.906V130.031C358.594 126.635 359.25 123.604 360.562 120.938C361.875 118.25 363.729 116.188 366.125 114.75C368.542 113.292 371.292 112.562 374.375 112.562C379 112.562 382.635 114.021 385.281 116.938C387.948 119.854 389.281 123.99 389.281 129.344V133.031H367.75C368.042 135.24 368.917 137.01 370.375 138.344C371.854 139.677 373.719 140.344 375.969 140.344C379.448 140.344 382.167 139.083 384.125 136.562L388.562 141.531C387.208 143.448 385.375 144.948 383.062 146.031C380.75 147.094 378.188 147.625 375.375 147.625ZM374.344 119.875C372.552 119.875 371.094 120.479 369.969 121.688C368.865 122.896 368.156 124.625 367.844 126.875H380.406V126.156C380.365 124.156 379.823 122.615 378.781 121.531C377.74 120.427 376.26 119.875 374.344 119.875Z" stroke="white" stroke-width="4" mask="url(#path-3-outside-3)"/>
      </svg>  
      <svg className="logo-engineer" viewBox="0 0 793 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Engineer</title>
        <mask id="path-4-outside-4" fill="white">  {/* engineer */}
          <path d="M425.938 147.625C420.979 147.625 416.938 146.104 413.812 143.062C410.708 140.021 409.156 135.969 409.156 130.906V130.031C409.156 126.635 409.812 123.604 411.125 120.938C412.438 118.25 414.292 116.188 416.688 114.75C419.104 113.292 421.854 112.562 424.938 112.562C429.562 112.562 433.198 114.021 435.844 116.938C438.51 119.854 439.844 123.99 439.844 129.344V133.031H418.312C418.604 135.24 419.479 137.01 420.938 138.344C422.417 139.677 424.281 140.344 426.531 140.344C430.01 140.344 432.729 139.083 434.688 136.562L439.125 141.531C437.771 143.448 435.938 144.948 433.625 146.031C431.312 147.094 428.75 147.625 425.938 147.625ZM424.906 119.875C423.115 119.875 421.656 120.479 420.531 121.688C419.427 122.896 418.719 124.625 418.406 126.875H430.969V126.156C430.927 124.156 430.385 122.615 429.344 121.531C428.302 120.427 426.823 119.875 424.906 119.875Z"/>
          <path d="M453.312 113.188L453.594 117.094C456.01 114.073 459.25 112.562 463.312 112.562C466.896 112.562 469.562 113.615 471.312 115.719C473.062 117.823 473.958 120.969 474 125.156V147H464.969V125.375C464.969 123.458 464.552 122.073 463.719 121.219C462.885 120.344 461.5 119.906 459.562 119.906C457.021 119.906 455.115 120.99 453.844 123.156V147H444.812V113.188H453.312Z"/>
          <path d="M479.562 129.844C479.562 124.656 480.792 120.479 483.25 117.312C485.729 114.146 489.062 112.562 493.25 112.562C496.958 112.562 499.844 113.833 501.906 116.375L502.281 113.188H510.469V145.875C510.469 148.833 509.792 151.406 508.438 153.594C507.104 155.781 505.219 157.448 502.781 158.594C500.344 159.74 497.49 160.312 494.219 160.312C491.74 160.312 489.323 159.812 486.969 158.812C484.615 157.833 482.833 156.562 481.625 155L485.625 149.5C487.875 152.021 490.604 153.281 493.812 153.281C496.208 153.281 498.073 152.635 499.406 151.344C500.74 150.073 501.406 148.26 501.406 145.906V144.094C499.323 146.448 496.583 147.625 493.188 147.625C489.125 147.625 485.833 146.042 483.312 142.875C480.812 139.688 479.562 135.469 479.562 130.219V129.844ZM488.594 130.5C488.594 133.562 489.208 135.969 490.438 137.719C491.667 139.448 493.354 140.312 495.5 140.312C498.25 140.312 500.219 139.281 501.406 137.219V123C500.198 120.938 498.25 119.906 495.562 119.906C493.396 119.906 491.688 120.792 490.438 122.562C489.208 124.333 488.594 126.979 488.594 130.5Z"/>
          <path d="M526.969 147H517.906V113.188H526.969V147ZM517.375 104.438C517.375 103.083 517.823 101.969 518.719 101.094C519.635 100.219 520.875 99.7812 522.438 99.7812C523.979 99.7812 525.208 100.219 526.125 101.094C527.042 101.969 527.5 103.083 527.5 104.438C527.5 105.812 527.031 106.938 526.094 107.812C525.177 108.688 523.958 109.125 522.438 109.125C520.917 109.125 519.688 108.688 518.75 107.812C517.833 106.938 517.375 105.812 517.375 104.438Z"/>
          <path d="M542.75 113.188L543.031 117.094C545.448 114.073 548.688 112.562 552.75 112.562C556.333 112.562 559 113.615 560.75 115.719C562.5 117.823 563.396 120.969 563.438 125.156V147H554.406V125.375C554.406 123.458 553.99 122.073 553.156 121.219C552.323 120.344 550.938 119.906 549 119.906C546.458 119.906 544.552 120.99 543.281 123.156V147H534.25V113.188H542.75Z"/>
          <path d="M585.875 147.625C580.917 147.625 576.875 146.104 573.75 143.062C570.646 140.021 569.094 135.969 569.094 130.906V130.031C569.094 126.635 569.75 123.604 571.062 120.938C572.375 118.25 574.229 116.188 576.625 114.75C579.042 113.292 581.792 112.562 584.875 112.562C589.5 112.562 593.135 114.021 595.781 116.938C598.448 119.854 599.781 123.99 599.781 129.344V133.031H578.25C578.542 135.24 579.417 137.01 580.875 138.344C582.354 139.677 584.219 140.344 586.469 140.344C589.948 140.344 592.667 139.083 594.625 136.562L599.062 141.531C597.708 143.448 595.875 144.948 593.562 146.031C591.25 147.094 588.688 147.625 585.875 147.625ZM584.844 119.875C583.052 119.875 581.594 120.479 580.469 121.688C579.365 122.896 578.656 124.625 578.344 126.875H590.906V126.156C590.865 124.156 590.323 122.615 589.281 121.531C588.24 120.427 586.76 119.875 584.844 119.875Z"/>
          <path d="M620.5 147.625C615.542 147.625 611.5 146.104 608.375 143.062C605.271 140.021 603.719 135.969 603.719 130.906V130.031C603.719 126.635 604.375 123.604 605.688 120.938C607 118.25 608.854 116.188 611.25 114.75C613.667 113.292 616.417 112.562 619.5 112.562C624.125 112.562 627.76 114.021 630.406 116.938C633.073 119.854 634.406 123.99 634.406 129.344V133.031H612.875C613.167 135.24 614.042 137.01 615.5 138.344C616.979 139.677 618.844 140.344 621.094 140.344C624.573 140.344 627.292 139.083 629.25 136.562L633.688 141.531C632.333 143.448 630.5 144.948 628.188 146.031C625.875 147.094 623.312 147.625 620.5 147.625ZM619.469 119.875C617.677 119.875 616.219 120.479 615.094 121.688C613.99 122.896 613.281 124.625 612.969 126.875H625.531V126.156C625.49 124.156 624.948 122.615 623.906 121.531C622.865 120.427 621.385 119.875 619.469 119.875Z"/>
          <path d="M658.562 121.656C657.333 121.49 656.25 121.406 655.312 121.406C651.896 121.406 649.656 122.562 648.594 124.875V147H639.562V113.188H648.094L648.344 117.219C650.156 114.115 652.667 112.562 655.875 112.562C656.875 112.562 657.812 112.698 658.688 112.969L658.562 121.656Z"/>
        </mask>  
        {/* engineer */}
        <path d="M425.938 147.625C420.979 147.625 416.938 146.104 413.812 143.062C410.708 140.021 409.156 135.969 409.156 130.906V130.031C409.156 126.635 409.812 123.604 411.125 120.938C412.438 118.25 414.292 116.188 416.688 114.75C419.104 113.292 421.854 112.562 424.938 112.562C429.562 112.562 433.198 114.021 435.844 116.938C438.51 119.854 439.844 123.99 439.844 129.344V133.031H418.312C418.604 135.24 419.479 137.01 420.938 138.344C422.417 139.677 424.281 140.344 426.531 140.344C430.01 140.344 432.729 139.083 434.688 136.562L439.125 141.531C437.771 143.448 435.938 144.948 433.625 146.031C431.312 147.094 428.75 147.625 425.938 147.625ZM424.906 119.875C423.115 119.875 421.656 120.479 420.531 121.688C419.427 122.896 418.719 124.625 418.406 126.875H430.969V126.156C430.927 124.156 430.385 122.615 429.344 121.531C428.302 120.427 426.823 119.875 424.906 119.875Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M453.312 113.188L453.594 117.094C456.01 114.073 459.25 112.562 463.312 112.562C466.896 112.562 469.562 113.615 471.312 115.719C473.062 117.823 473.958 120.969 474 125.156V147H464.969V125.375C464.969 123.458 464.552 122.073 463.719 121.219C462.885 120.344 461.5 119.906 459.562 119.906C457.021 119.906 455.115 120.99 453.844 123.156V147H444.812V113.188H453.312Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M479.562 129.844C479.562 124.656 480.792 120.479 483.25 117.312C485.729 114.146 489.062 112.562 493.25 112.562C496.958 112.562 499.844 113.833 501.906 116.375L502.281 113.188H510.469V145.875C510.469 148.833 509.792 151.406 508.438 153.594C507.104 155.781 505.219 157.448 502.781 158.594C500.344 159.74 497.49 160.312 494.219 160.312C491.74 160.312 489.323 159.812 486.969 158.812C484.615 157.833 482.833 156.562 481.625 155L485.625 149.5C487.875 152.021 490.604 153.281 493.812 153.281C496.208 153.281 498.073 152.635 499.406 151.344C500.74 150.073 501.406 148.26 501.406 145.906V144.094C499.323 146.448 496.583 147.625 493.188 147.625C489.125 147.625 485.833 146.042 483.312 142.875C480.812 139.688 479.562 135.469 479.562 130.219V129.844ZM488.594 130.5C488.594 133.562 489.208 135.969 490.438 137.719C491.667 139.448 493.354 140.312 495.5 140.312C498.25 140.312 500.219 139.281 501.406 137.219V123C500.198 120.938 498.25 119.906 495.562 119.906C493.396 119.906 491.688 120.792 490.438 122.562C489.208 124.333 488.594 126.979 488.594 130.5Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M526.969 147H517.906V113.188H526.969V147ZM517.375 104.438C517.375 103.083 517.823 101.969 518.719 101.094C519.635 100.219 520.875 99.7812 522.438 99.7812C523.979 99.7812 525.208 100.219 526.125 101.094C527.042 101.969 527.5 103.083 527.5 104.438C527.5 105.812 527.031 106.938 526.094 107.812C525.177 108.688 523.958 109.125 522.438 109.125C520.917 109.125 519.688 108.688 518.75 107.812C517.833 106.938 517.375 105.812 517.375 104.438Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M542.75 113.188L543.031 117.094C545.448 114.073 548.688 112.562 552.75 112.562C556.333 112.562 559 113.615 560.75 115.719C562.5 117.823 563.396 120.969 563.438 125.156V147H554.406V125.375C554.406 123.458 553.99 122.073 553.156 121.219C552.323 120.344 550.938 119.906 549 119.906C546.458 119.906 544.552 120.99 543.281 123.156V147H534.25V113.188H542.75Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M585.875 147.625C580.917 147.625 576.875 146.104 573.75 143.062C570.646 140.021 569.094 135.969 569.094 130.906V130.031C569.094 126.635 569.75 123.604 571.062 120.938C572.375 118.25 574.229 116.188 576.625 114.75C579.042 113.292 581.792 112.562 584.875 112.562C589.5 112.562 593.135 114.021 595.781 116.938C598.448 119.854 599.781 123.99 599.781 129.344V133.031H578.25C578.542 135.24 579.417 137.01 580.875 138.344C582.354 139.677 584.219 140.344 586.469 140.344C589.948 140.344 592.667 139.083 594.625 136.562L599.062 141.531C597.708 143.448 595.875 144.948 593.562 146.031C591.25 147.094 588.688 147.625 585.875 147.625ZM584.844 119.875C583.052 119.875 581.594 120.479 580.469 121.688C579.365 122.896 578.656 124.625 578.344 126.875H590.906V126.156C590.865 124.156 590.323 122.615 589.281 121.531C588.24 120.427 586.76 119.875 584.844 119.875Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M620.5 147.625C615.542 147.625 611.5 146.104 608.375 143.062C605.271 140.021 603.719 135.969 603.719 130.906V130.031C603.719 126.635 604.375 123.604 605.688 120.938C607 118.25 608.854 116.188 611.25 114.75C613.667 113.292 616.417 112.562 619.5 112.562C624.125 112.562 627.76 114.021 630.406 116.938C633.073 119.854 634.406 123.99 634.406 129.344V133.031H612.875C613.167 135.24 614.042 137.01 615.5 138.344C616.979 139.677 618.844 140.344 621.094 140.344C624.573 140.344 627.292 139.083 629.25 136.562L633.688 141.531C632.333 143.448 630.5 144.948 628.188 146.031C625.875 147.094 623.312 147.625 620.5 147.625ZM619.469 119.875C617.677 119.875 616.219 120.479 615.094 121.688C613.99 122.896 613.281 124.625 612.969 126.875H625.531V126.156C625.49 124.156 624.948 122.615 623.906 121.531C622.865 120.427 621.385 119.875 619.469 119.875Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
        <path d="M658.562 121.656C657.333 121.49 656.25 121.406 655.312 121.406C651.896 121.406 649.656 122.562 648.594 124.875V147H639.562V113.188H648.094L648.344 117.219C650.156 114.115 652.667 112.562 655.875 112.562C656.875 112.562 657.812 112.698 658.688 112.969L658.562 121.656Z" stroke="white" stroke-width="4" mask="url(#path-4-outside-4)"/>
      </svg>
      
    </div>
  );
}

export default LogoBanner;